// src/Home.tsx
import React from 'react';
import { Box, Typography, Button, Container, Grid, Card, CardContent, CardActionArea, Divider } from '@mui/material';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const goTo = (path: string) => {
    navigate(`/${path}`)
  }

  return (
    <Container maxWidth="md" sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h3" gutterBottom>
        Welcome to <br/><b>DevTools</b>
      </Typography>
      <Typography variant="h6" color="textSecondary" paragraph>
      Your hub for powerful tools designed to facilitate your life.
      </Typography>
      
      {/* <Divider style={{ marginTop: 40, marginBottom: 40 }} /> */}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
      <Grid container spacing={3} justifyContent="center">
        
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            
            {/* LinkComponent={Link} Try to use Link component below */}
            <CardActionArea onClick={() => goTo("json")} sx={{ height: '100%' }}>
              <Typography variant="h6" sx={{ backgroundColor: '#1976d2', p: 2, borderRadius: 0 }} color="white">
                Json Mapper
              </Typography>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography color="textSecondary">Extract content from a JSON data mapping their keys and indexes.</Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', pb: 2 }}>
                <DataObjectIcon fontSize="large" htmlColor="gray" />
              </Box>
            </CardActionArea>
          </Card>
        </Grid>
        
      </Grid>
      </Box>
    </Container>
  );
};

export default Home;
