// src/App.tsx
import React, { useState } from 'react';
import { 
  Button,
  Snackbar,
  IconButton,
  TextField,
  Container,
  Typography,
  Box,
  FormHelperText,
 } from '@mui/material';
import { Close as CloseIcon, ContentCopy as CopyIcon } from '@mui/icons-material';

class Argument<T> {
  value: T;

  constructor(value: T) {
      this.value = value;
  }
}

class Index extends Argument<number> {
  constructor(index: number) {
      super(index);
  }
}

class Key extends Argument<string> {
  constructor(key: string) {
      super(key);
  }
}

function resolveArgument(it: string): Argument<any> {
  const intValue = parseInt(it, 10);

  if (!isNaN(intValue)) {
      return new Index(intValue);
  } else {
      return new Key(it);
  }
}


function parseJson(input: string, args: Argument<any>[]): { success: boolean, value?: string, error?: any } {
  try {
      let obj: any = JSON.parse(input);

      for (let i = 0; i < args.length - 1; i++) {
          if (obj === null) break;

          const it = args[i];

          obj = (it instanceof Index) ? obj[it.value] :
                (it instanceof Key) ? obj[it.value] || null : null;
      }

      const arg = args[args.length - 1];

      if (obj) {
          obj = (arg instanceof Index) ? obj[arg.value] :
                (arg instanceof Key) ? (() => {
                    if (arg.value.trim()) {
                        if (Array.isArray(obj)) {
                          console.log("is array", obj)
                          return obj.map((item: any) => item instanceof Object ? item[arg.value] || null : null);
                        } else if (obj instanceof Object) {
                          return obj[arg.value];
                        }
                    }
                    return null;
                })() : null;
      }

      return { success: true, value: JSON.stringify(obj) };
  } catch (exception) {
      console.error(exception);
      return { success: false, error: exception };
  }
}

interface FormData {
  jsonData: string;
  mapper: string;
}

const JsonMapper: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    jsonData: '',
    mapper: '',
  });

  const [result, setResult] = useState<string | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isCopyButtonVisible, setIsCopyButtonVisible] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleChange = (field: keyof FormData, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle form submission logic here
    // For example, set the result based on some processing of form data
    
    if(formData.mapper.length > 0) {
      const args = formData.mapper.split(".").map(resolveArgument);
      const processedResult = parseJson(formData.jsonData, args);
      setResult(processedResult.value ?? processedResult.error);
    } else {
      setResult(formData.jsonData)
    }
    setIsFormSubmitted(true);
  };

  const handleCopyClick = () => {
    // Implement logic to copy result to clipboard
    navigator.clipboard.writeText(result || '');
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const prettifyJson = (jsonString: string) => {
    try {
      const parsedJson = JSON.parse(jsonString);
      return JSON.stringify(parsedJson, null, 2); // 2 spaces for indentation
    } catch (error) {
      return jsonString;
    }
  };

  const handleResultMouseEnter = () => {
    setIsCopyButtonVisible(true);
  };

  const handleResultMouseLeave = () => {
    setIsCopyButtonVisible(false);
  };

  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: 64 }}>
      <div>
        <Typography variant="h5" align="center">
          Json Tools
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            multiline
            rows={5}
            variant="outlined"
            margin="normal"
            fullWidth
            label="JSON data"
            value={formData.jsonData}
            onChange={(e) => handleChange('jsonData', e.target.value)}
            InputProps={{
              value: prettifyJson(formData.jsonData),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Mapper"
            value={formData.mapper}
            onChange={(e) => handleChange('mapper', e.target.value.replaceAll(/\s/g,''))}
          />
          <FormHelperText id="component-helper-text">
            Use a dot (.) as the argument separator
          </FormHelperText>

          <section style={{ marginTop: 32 }}>
            <Button type="submit" variant="contained" color="primary" fullWidth >
              Submit
            </Button>
            {isFormSubmitted && (<>
              <Box position="relative" 
                onMouseEnter={handleResultMouseEnter}
                onMouseLeave={handleResultMouseLeave}>
                <TextField
                  multiline
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Result"
                  disabled
                  value={result}
                  InputProps={{
                    value: result !== null ? prettifyJson(result) : result,
                  }}
                />
                {isCopyButtonVisible && (
                  <Button
                    variant="outlined"
                    style={{ position: 'absolute', top: 24, right: 8 }}
                    onClick={handleCopyClick}
                  >
                    <CopyIcon fontSize="small" style={{ marginRight: 8}}/>
                     Copy
                  </Button>
                )}
              </Box>
            </>)}
          </section>
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="Copied!"
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
    </Container>
  );
};

export default JsonMapper;
