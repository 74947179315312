// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Outlet, useLocation } from 'react-router-dom';
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Typography,
  AppBar,
  ListItemButton,
} from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import DataObjectIcon from '@mui/icons-material/DataObject';
import HomeIcon from '@mui/icons-material/Home';

const drawerWidth = 240;

const Root: React.FC = () => {
  const { pathname } = useLocation();

  const isSelectedPath = (value: string) => {
    return pathname == value
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* AppBar */}
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
        <CodeIcon /> 
          <Typography variant="h6" noWrap component="div" style={{ marginLeft: 8 }}>
            Dev Tools
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Sidebar / Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>

            <ListItemButton component={Link} to="/" selected={isSelectedPath("/")}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>

            <ListItemButton component={Link} to="/json" selected={isSelectedPath("/json")}>
              <ListItemIcon>
                <DataObjectIcon />
              </ListItemIcon>
              <ListItemText primary="Json Mapper" />
            </ListItemButton>
            
          </List>
        </Box>
      </Drawer>

      {/* Main Content Area with Outlet for nested routes */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: '64px', // Height of AppBar
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Root;
